<template>
  <div class="app-content" :style="{ visibility: 'visible', height:docmHeight+'px'}">
    <div class="title">手机注册</div>
  
    <div class="reg-form">

      <div>
        <div class="reg-form-item">
          <input
            class="form-input"
            v-model="phone"
            @input="phoneError=false"
            placeholder="请输入手机号码"
            type="number"  />
        </div>
        <div v-if="phoneError"
          style="line-height: 26px; margin-top: 5px; color: red" >请输入正确的手机号码</div>
      </div>

      <div>
        <div style="display: flex">
          <div class="reg-form-item" style="width: 1px; flex: 1">
            <input
              class="form-input"
              style="flex: 1"
              v-model="valicode"
              @input="valicodeError=false"
              placeholder="请输入验证码"
              type="text"/>

          </div>

          <div v-if="smsCD" class="form-valicode-btn disable">{{'已发送' + smsCD + 's'}})</div>
          <div v-else class="form-valicode-btn" @click="sendSms">获取验证码</div>
        </div>
        <div v-if="valicodeError" 
          style="line-height: 26px; margin-top: 5px; color: red">验证码错误</div>
      </div>

      <div class="reg-form-item">
        <input
          class="form-input"
          v-model="code"
          type="text"
          placeholder="邀请码(可选)"/>
      </div>

      <div class="submit-btn"
        @click="registered" @dblclick="registered">注册</div>
    </div>

    <div style="flex:1"></div>
    <div class="foot-tips">
      <p>已注册521NFT账号，<span style="cursor: pointer" @click="gotoDownload">请直接下载</span></p>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { ElLoading } from 'element-plus'

import { sendSms, login } from "../http/index";

const SMS_CD = 60*1000;
export default {
  name: "HelloWorld",

  props: {
    msg: String,
  },

  data() {
    return {
      docmHeight: document.documentElement.clientHeight,  // 默认屏幕高度
      phone: "", //手机号
      phoneError: false, //true手机号错误

      valicode: "", //验证码
      valicodeError: false, //tur验证码错误
      smsCD: 0, //验证码重新发送倒计时

      code: '', //邀请码
    };
  },

	created(){
    // console.log(this.$route)
    var path = this.$route.path;
    var arr = path.split('/');
    if (arr.length==2) { 
      this.code = arr[1];
    }
    // console.log(arr);
    this.startSMSCD();
	},

  methods: {

    /********************************** http网络 */
    // 发送验证码
    httpSendSms() {

      const loading = ElLoading.service({'lock':true, 'background':'rgba(0, 0, 0, 0.7)'});

      var param = {'phone': this.phone, 'sendType':'LOGIN'};
      sendSms(
        param,
        (data) => {
          loading.close();
          localStorage.setItem("sms_cd_end_login",new Date().getTime() + SMS_CD);
          this.startSMSCD();
        },
        (err) => {
          loading.close();
          ElMessage.error(err.msg)
        }
      );
    },

    // 注册
    httpLogin() {

      const loading = ElLoading.service({'lock':true, 'background':'rgba(0, 0, 0, 0.7)'});
      var inviteCode = this.code ? this.code : undefined;
      var param = {'phone': this.phone, 'valicode':this.valicode, inviteCode};
      login(
        param,
        (data) => {
          loading.close();
          if (data.isNew) {
            this.phone = '';
            this.valicode = '';
            this.code = '';
            ElMessage({message: '注册成功', type: 'success'});
            setTimeout(() => {
              this.gotoDownload();
            }, 1500);
          } else {
            ElMessage.error('账户已存在')
          }
        },
        (err) => {
          loading.close();
          console.log(err);
          ElMessage.error(err.msg)
        }
      );
    },

    /********************************** 输入框事件 */
    //检查手机号
    checkPhone() {
      var bol = (!/^1[123456789]\d{9}$/.test(this.phone));
      this.phoneError = bol;
      return bol;
    },

    //检查验证码
    checkValicode() {
      var bol = this.valicode.length != 6;
      this.valicodeError = bol;
      return bol;
    },

    /********************************** 其实事件 */
		//开始倒计时
		startSMSCD(){

			var endTime = localStorage.getItem("sms_cd_end_login");
			var offset = endTime - new Date().getTime();
			if(offset<=0){
				this.smsCD = 0;
			}else{
				this.smsCD = parseInt(offset / 1000);
		  	setTimeout(()=>{this.startSMSCD()},1000);
			}
		},

    /********************************** 输入框事件 */
    //发送验证码
    sendSms() {
      if (this.phone.length == 0) {
        ElMessage({'message': '请输入手机号', 'type': 'warning'})
        return;
      }

      if (this.checkPhone()) return;

      this.httpSendSms();
    },

    //注册
    registered() {

      if (this.phone.length == 0) {
        ElMessage({'message': '请输入手机号', 'type': 'warning'})
        return;
      }

      if (this.checkPhone()) return;


      if (this.valicode.length == 0) {
        ElMessage({'message': '请输入验证码', 'type': 'warning'})
        return;
      }

      if (this.checkValicode()) return;

      this.httpLogin();
    },

    //下载
    gotoDownload() {
      window.open('https://m.xhy6688.com')
    }
  },
};
</script>

<style scoped>
.app-content {
  display: flex;
  flex-direction: column;
  background: white;
  box-sizing: border-box;
}

.title {
  font-size: 30px;
  font-weight: 500;
  padding: 30px 20px 0px 20px;
  color: #333333;
}


.reg-form {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 20px;
}
.reg-form-item {
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e7e9;
  background: white;
}

.form-input {
  border: none;
  outline:none;
  width: 100%;
}
.form-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #848e9c;
}
.form-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #848e9c;
}
.form-input::-ms-input-placeholder {
  /* IE 10+ */
  color: #848e9c;
}
.form-input::-moz-placeholder {
  /* Firefox 18- */
  color: #848e9c;
}

.form-valicode-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 25px;
  padding: 5px 28px;
  border: 1px solid #2684ff;
  border-radius: 3px;
  color: #2684ff;
}
.form-valicode-btn.disable {
  border: 1px solid rgba(169, 169, 169, 1);
  color: #a9a9a9;
}

.submit-btn {
  text-align: center;
  width: 100%;
  background: #2684ff;
  padding: 10px 0;
  margin-top: 25px;
  color: white;
  border-radius: 3px;
}

.foot-tips {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  justify-content: center;
  margin-bottom: 40px;
}
.foot-tips > p {
  font-size: 14px !important;
  color: #787e94;
}
.foot-tips > p > span {
  font-size: 14px !important;
  color: #22abfd;
  margin-left: 5px;
}
</style>
