import { createRouter, createWebHistory } from 'vue-router'
import logon from '../views/logon.vue'

const routes = [
  {
    path: '/',
    name: 'logon',
    component: logon
  }
  ,{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/logon.vue')
  }
  ,{
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/logon')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
