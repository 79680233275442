import axios from 'axios'
// import META from "@/utils/META";
import { VueAxios } from 'axios'
// let url = "http://192.168.2.63:8007" //胡豪本地
// let url = "http://116.205.150.234:8886" //测试
// let url = "http://register521nft.metahere.com" //测试
let url = "https://521nft.xhy6688.com" //正式


// 创建 axios 实例
const request = axios.create({
  baseURL: url +'/api',
  timeout: 30000 // 请求超时时间
})
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
  }
  return Promise.reject(error)
}

// 请求拦截
request.interceptors.request.use(config => {

  // 补充请求头
  config.headers['clientVersion'] = '999999';
  config.headers['sourceType'] = 'h5';

  //补充头
  if (config.header) {
    Object.assign(config.headers, config.header)
  }

  const token = localStorage.getItem('ACCESS_TOKEN');
  if (token) {
    config.headers['token'] = token;
  }

  return config
}, errorHandler)

// 响应拦截
request.interceptors.response.use((response) => {
  return response.data;
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
