import request from './request'
import aes from "../uitls/aes"
import rsa from '../uitls/rsa'
import geetest from '../uitls/geetest'
import userApi from "./api"
import { ElMessageBox } from 'element-plus'

/**
 * 
 * @param {string} url 方法地址
 * @param {object} param 参数
 * @param {function} success  成功回调
 * @param {function} fail 失败回调
 * @param {boolean} verify true显示极验
 * @returns 
 */
async function post(url, param, success, fail, verify=false) {

  // 是否需要人机校验
  var verification;
  if (verify) {
    try {
      verification = await geetest.check4(url);
    } catch (e) {
      fail({ 'status': '-1', 'msg': '人机校验失败' });
      return;
    }
  }

  var map = { 'url': url, 'method': 'post', 'data': param };

   //验证头
  if (verification) {
    addHeader(map, { 'verification': verification });
  }

  request(map).then(data => {
    if (data.status == 200) {
      success(data.data);
    } else {
      error(data, fail, () => {
        //错误反馈，需要校验极验
        post(url, param, success, fail, true);
      });
    }
  }).catch(error => {
    try {
      var map = JSON.stringify(error);
      map = JSON.parse(map);
      if (map.status == 418) {
        fail({ 'status': '418', 'msg': '操作频率过高，请稍候再试，频繁请求将会被禁止访问' });
        return;
      }
    } catch (error) {
      console.log('转换错误' + error);
    }
    fail({ 'status': '-1', 'msg': '网络异常' });
  })
}

async function postx(url, param, success, fail, verify=false) {

  // 是否需要人机校验
  var verification;
  if (verify) {
    try {
      verification = await geetest.check4(url, param);
    } catch (e) {
      fail(e);
      return;
    }
  }

  getEncryptParam(param, (aesStr, aesKey, fixedKey) => {

    //配置请求参数
    var map = { 'url': url, 'method': 'post', 'data': { 'aesData': aesStr } };
    
    //验证头
    if (verification) {
      addHeader(map, { 'verification': verification });
    }

    //登入极验加请求头
    if (verification && url == 'user/sendSms' && param.sendType == 'LOGIN') {
      addHeader(map, { 'verificationType': 'login' });
    }

    var value = request(map);
    value.then(data => {

      var newData = aes.decrypt(data, aesKey);
      if (!newData) {
        newData = aes.decrypt(data, fixedKey);
      }
      newData = JSON.parse(newData);

      if (newData.status == 200) {
        success(newData.data);
      } else {
        error(newData, fail, () => {
          //错误反馈，需要校验极验
          postx(url, param, success, fail, queue, true);
        });
      }
    }).catch(error => {
      console.log(error);
      try {
        var map = JSON.stringify(error);
        map = JSON.parse(map);
        if (map.status == 418) {
          fail({ 'status': '418', 'msg': '操作频率过高，请稍候再试，频繁请求将会被禁止访问' });
          return;
        }
      } catch (error) {
        console.log('转换错误' + error);
      }

      fail({ 'status': '-1', 'msg': '网络异常' });
    })
  }, fail);
}

//获取加密参数
function getEncryptParam(param, success, fail) {

  var str = JSON.stringify(param);

  const token = localStorage.getItem('ACCESS_TOKEN');
  const fixedKey = 'd3SReEQJaqJRZMUz';
  var aesKey = token ? localStorage.getItem('ACCESS_AESKEY') : fixedKey;
  if (aesKey) {
    var aesStr = aes.encrypt(str, aesKey);
    success(aesStr, aesKey, fixedKey);
    return;
  }

  post(userApi.getSecretKey, {}, (data) => {
    var map = rsa.decrypt(data);
    map = JSON.parse(map);
    localStorage.setItem('ACCESS_AESKEY', map.aesKey);
    var aesStr = aes.encrypt(str, map.aesKey);
    success(aesStr, map.aesKey, fixedKey);
  }, fail);
}

//添加头
function addHeader(map, param) {
  var header = map['header'] ? map['header'] : {};
  Object.assign(header, param);
  map['header'] = header;
}

//错误处理
let ismodal = false
function error(data, fail, verify) {

  if (data.status == 486) {

    if (ismodal) return;
    ElMessageBox.alert('当前版本过低，请更新版本...', '提示', {
      confirmButtonText: '更新',
      callback: () => {
        location.reload();
      },
    })
    ismodal = true
    return
  }

  //极验校验
  if (data.status == 489) {
    verify();
    return;
  }

  fail(data)
}


//发送短信
export function sendSms(param, success, fail) { postx(userApi.sendSms, param, success, fail, true) }
//注册
export function login(param, success, fail) { postx(userApi.login, param, success, fail) }