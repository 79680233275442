export default {
	check4(url, param){

		var captchaId = (url=='user/sendSms' && param.sendType=='LOGIN') ? 
		'e98af2770ec559c2fecb1787bb05b64f' : 
		'b25da201ed9c5c720ca5a7bb15af7670';

		return new Promise((resolve, reject)=>{
		 initGeetest4({
			captchaId: captchaId,
			product: 'bind'
		 }, function (captchaObj) {

			captchaObj.onReady(function(){
				captchaObj.showCaptcha();
			}).onSuccess(function(){
				var res = captchaObj.getValidate();
				resolve(JSON.stringify(res));
			}).onError(function(error){
				reject({ 'status': '-1', 'msg': '人机校验失败'});
			});
			
			captchaObj.onClose(function () {
				reject({ 'status': '-1', 'msg': '取消校验'});
			});

		 });
		});
	 }
};