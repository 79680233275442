/* 产引入jsencrypt实现数据RSA加密 */
import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA

// 公钥key
const publicKey = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMzA2ODczMzg1NiIsImlhdCI6MTY0OTQ4OTg" +
"xOSwiZXhwIjoxNjUwMDk0NjE5fQ.xI1k0DH8fVCGh178Bjq8RXI5BjD3dSc1PtKIT75X7XS_jOqgnmgD8JxN7vJefuYqUoJR4if0MJzaZxSfjVMlYA";

const privateKey = "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCx9GqoXSMyTzEwJL+b7WOHYW+W" + 
"zia+8geqv97EgaJHd+TH0pKmFa9kfRhNje0PvU/jxqiy6QOQX5nX91c4k7NgUxTsE07utUANwlI/" + 
"J2Vv9poW/sq4xqbvzMXLF3SPpkHlNTW8m+2ZXpP7OCxKvSRV80EFomBBtgTPpd+rgyzeOwLruiWy" + 
"fu1N2WnKbhGTSj0KwPNyKftigwJX+VHmdsQBrIZ3J+vDoH1U5mYFEoAhQYIqFI6CN6eIiBd6EMJu" + 
"+9ukyEhQ6JmC2PHdpTwIwePvvd9d0FJv4e8bW9zycAsmZjqzPBzax4TlozELkV3RZ4/LPc+loLh9" + 
"JYqUEOuclrBDAgMBAAECggEAVy8UAiB/WeqKgre+WDpZhL4r2YpuWSZiszkFfui0BKesoml8B3qg" + 
"T28qNPTPq+Jn/YQaqmyWnmj4CmLXjJRwUUTi/zYGedS66QLHIMHAZLZAj/uMfjJ1Hw8H6JQ5OxeN" + 
"OSp0hU8vfPBlN7Mxn/xCjQl8VbJTzl3KxaDSkEjX9pfIRB7sytYtBlxSMnbET2fWa+b8dX2SMgzo" + 
"LGr9PSOBSG72FpVktBx1N/3tYNMxxjSoSjOegwB+5vNXs5GFUWsJuI32aF3eZWlA5G5Sl1Lm6dvg" + 
"As7ifaGRUf7d6KjIbeHtMpBCpVWhVB8onTW7JZYYeMyObQipVMdGlIzbEe13IQKBgQDu05yPK3/h" + 
"MFb4KeAzU/l1aa2VizhIPLHvAerwxd1S4NKJijmhyvACE5NHWJ//Bc/2PlvkCJCcNvBEfWGedzh9" + 
"PGBsr2TKAmMamLcdZZw8fUE/qddYssc/63sn6wEpzTd/iIPwVkniOwR4yTXjaggE3CFjLASzuRVT" + 
"hE0beN+bRwKBgQC+wEIxckZDmox8SfVus9EyHrA+EdrmNd2flpTCt4o96ODjdoztYDwQUQXFGRFv" + 
"ZVGaN7i5WRkr+x1umTvoBMW8VYcnEdZr8IhqcGfcUefD23RI916zqsQCXaRNw+1rpPCk3sWnm/5E" + 
"mhiK7+3D/CQkiVWUvg7HxrSgFaM42hJJJQKBgDbfpC85HW7OPo1SgdMJTl3t5BZiIJJob7GszSB5" + 
"Nfse8QDtI4TLLg4sFUDlWg2sdjXr6g0g8qkR7m6uKEnStsX/o2AVhKDtn3uy7w/wEs/LL7zC91nH" + 
"6gQDxQ5M7r1+C2xgH4CPMvap/MML6DfSWvlS4tD4gjQRKJdB5L2VLjttAoGAa12b5GkF05dx66NV" + 
"WfIslaFgYg96vokjLWh8Yv0b+qzRqDZaLGI2bJiuPrpVbfYBcGRcvTbBTFgTwOTPK1FEWzVo1Tzd" + 
"C/RfVEngq8oOQdeMJGXYEBjWKt66VGsrf6NfL+2UIztSaxqxJcLWebG6RjZmzUoM17WohVKohJ96" + 
"G2ECgYEA0j3YjwQVrRGIKTmCWcvn9wOVm55P2oh/XCxhVhYgzkAPDa1m2v70At9FDXQa7KcQC+MH" + 
"LS6+S3XI2UA2LVtKC+hz0wug3tVakfer3iD6nxf16bOkQG2T53+yJgVl4jJXXcu5UFuKNi9hGix1" + 
"zVkmGnGG2qQmydl2r1rB51yzP9o=";


export default {
  encrypt(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    var result = jsencrypt.encrypt(data)
    return result;
  },
  decrypt(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPrivateKey(privateKey)
    var result = jsencrypt.decrypt(data)
    return result;
  }
}